<template>
  <b-modal
    id="edit-condition-modal"
    ref="edit-condition-modal"
    scrollable
    :title="condition.sumlageName + $t('views.conditions.EditNameCondition')"
    size="md"
    @show="busy = true"
    @shown="GetValues()"
    :busy="busy"
  >
    <b-overlay :show="busy">
      <b-form-group
        label-for="condition-input-name"
        :invalid-feedback="$t('views.conditions.ConditionNameRequired')"
        :state="ConditionNameState"
      >
        <b-form-input
          v-model="name"
          :placeholder="$t('views.conditions.EnterConditionName')"
          id="condition-input-name"
          class="mb-2"
          trim
          :state="ConditionNameState"
        />
      </b-form-group>
      <div v-for="(surcharge, index) in surchargeTypes" v-bind:key="index">
        <b-row class="mb-2">
          <b-col>
            {{ surcharge.surchargeName }}
          </b-col>
          <b-col>
            <custom-percent-input
              v-model="surcharge.value"
              suffix
            ></custom-percent-input>
          </b-col>
        </b-row>
      </div>
      <template #overlay>
        <simple-loader />
      </template>
    </b-overlay>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button @click="hideModal">{{ $t("general.cancel") }}</b-button
        ><b-button variant="primary" @click="Update">{{
          $t("general.ok")
        }}</b-button></slot
      >
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    condition: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      busy: false,
      name: "",
      ConditionNameState: null,
    };
  },
  methods: {
    ...mapActions("Conditions", [
      "GetSurchargeTypes",
      "UpdateSurcharges",
      "UpdateCondition",
    ]),
    hideModal() {
      this.$bvModal.hide("edit-condition-modal");
    },
    GetValues() {
      this.name = this.condition.sumlageName;
      this.busy = false;
      this.ConditionNameState = null;
      this.tempConditionName = this.condition.sumlageName;
      /* this.GetSurchargeTypes({
        session: this.$session,
        api: this.$api,
        idCondition: this.condition.idCondition,
      })
        .then(() => {
          this.busy = false;
          this.$forceUpdate();
        })
        .catch((e) => {
          this.busy = false;
          console.log(e);
        }); */
    },
    Update(bvModalEvt) {
      bvModalEvt.preventDefault();

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$emit("renamed", this.name);

      this.UpdateCondition({
        api: this.$api,
        session: this.$session,
        condition: this.condition,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then(() => {
          this.$bvModal.hide("edit-condition-modal");
        })
        .catch((e) => {
          console.log(e);
          this.$emit("renamed", this.tempConditionName);
          this.$bvModal.hide("edit-condition-modal");
        });

      var changes = [];
      for (var i = 0; i < this.surchargeTypes.length; i++) {
        var newItem = {
          Value: this.surchargeTypes[i].value,
          idSurcharge: this.surchargeTypes[i].idSurcharge,
        };
        changes.push(newItem);
      }

      this.UpdateSurcharges({
        api: this.$api,
        session: this.$session,
        idCondition: this.condition.idCondition,
        values: changes,
      }).catch((e) => {
        console.log(e);
      });
    },
    checkFormValidity() {
      let check = (this.ConditionNameState = true);
      if (!this.name) this.ConditionNameState = check = false;
      return check;
    },
  },
  computed: {
    ...mapGetters("Conditions", ["surchargeTypes"]),
  },
};
</script>
