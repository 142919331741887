<template>
  <div>
    <Alert />
    <b-card class="h-100" no-body>
      <b-card-header>
        <b-row class="mb-3">
          <b-col>
            <h3>{{ $t("views.conditions.title") }}</h3>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox v-model="Enable" name="check-button" switch>{{
              $t("views.conditions.showEnabled")
            }}</b-form-checkbox>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                />
                <b-button
                  v-if="keyword == ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                >
                  <b-icon-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.conditions.AddCondition')"
                  @click="showAddCondition = !showAddCondition"
                >
                  <b-icon-plus />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.conditions.EditCondition')"
                  :disabled="isSelected"
                  @click="EditCondition"
                >
                  <b-icon-pencil />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.conditions.deactivateCondition')"
                  :disabled="isSelected"
                  @click="RemoveCondition"
                >
                  <b-icon-trash />
                </b-button>
                <b-button
                  v-show="!Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.conditions.ActivateCondition')"
                  :disabled="isSelected"
                  @click="ActiveCondition"
                >
                  <b-icon-check />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-header>

      <AddCondition class="m-3 p-3" v-bind:show.sync="showAddCondition" />
      <b-card-body class="overflow-auto p-0">
        <b-table
          ref="Conditions"
          :busy="busy"
          :fields="fields"
          :items="conditions"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="keyword"
          :filter-ignored-fields="ignoredFields"
          :selectable="selectable"
          small
          outlined
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template v-slot:table-busy>
            <simple-loader />
          </template>
          <template v-slot:cell(sumlageName)="data">
            <div v-if="!data.item.Edit">{{ data.value }}</div>
            <div v-else>
              <b-input
                v-model="data.item.sumlageName"
                block
                size="sm"
                :placeholder="$t('views.conditions.EnterConditionName')"
              />
            </div>
          </template>
          <template v-slot:cell(enabled)="data">
            <div v-if="!data.item.Edit">
              <div v-if="data.value" class="text-success">
                <strong>
                  <b-icon-check />
                </strong>
              </div>
              <div v-else class="text-danger">
                <strong>
                  <b-icon-x />
                </strong>
              </div>
            </div>
            <b-row v-else>
              <b-col>
                <b-button
                  variant="success"
                  block
                  size="sm"
                  v-on:click="updateCondition()"
                  >{{ $t("general.save") }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  variant="danger"
                  block
                  size="sm"
                  v-on:click="cancelUpdate()"
                  >{{ $t("views.conditions.Cancel") }}</b-button
                >
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card-body>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        pills
        size="sm"
        align="center"
      ></b-pagination>
      <EditCondition
        :condition="currentCondition"
        @renamed="currentCondition.sumlageName = $event"
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddCondition from "@/components/AddCondition";
import EditCondition from "@/components/EditCondition";
import Alert from "@/components/general/alert";

export default {
  components: {
    AddCondition,
    EditCondition,
    Alert,
  },
  watch: {
    Enable() {
      this.busy = true;
      this.getConditions({
        session: this.$session,
        api: this.$api,
        enabled: this.Enable,
      })
        .then(() => {
          this.busy = false;
        })
        .catch((e) => {
          console.log(e);
          this.busy = false;
        });
    },
  },
  data() {
    return {
      Enable: true,
      perPage: 10,
      currentPage: 1,
      busy: false,
      selected: [],
      currentCondition: { sumlageName: "" },
      showAddCondition: false,
      keyword: "",
      selectable: true,
      ignoredFields: ["Enabled"],
    };
  },
  methods: {
    ...mapActions("Conditions", [
      "getConditions",
      "UpdateCondition",
      "ActivateCondition",
      "DeleteCondition",
      "GetSurchargeTypes",
    ]),
    GetValues() {
      this.GetSurchargeTypes({
        session: this.$session,
        api: this.$api,
        idCondition: this.selected[0].idCondition,
      })
        .finally(() => {
          this.currentCondition = this.selected[0];
          this.$bvModal.show("edit-condition-modal");
          //this.busy = false;
          this.$forceUpdate();
        })
        .catch((e) => {
          //this.busy = false;
          console.log(e);
        });
    },
    toast(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    EditCondition() {
      this.GetValues();
    },
    updateCondition() {
      const that = this;
      this.UpdateCondition({
        api: this.$api,
        session: this.$session,
        condition: this.currentCondition,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then((resp) => {
          that.busy = false;
          that.toast(
            that.$t("views.conditions.ConditionUpdated"),
            resp.data.message
          );
          that.currentCondition.Edit = false;
          that.selectable = true;
          that.$refs.Conditions.refresh();
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
          that.currentCondition.Edit = false;
          that.selectable = true;
          that.$refs.Conditions.refresh();
        });
    },
    cancelUpdate() {
      this.currentCondition.Edit = false;
      this.selectable = true;
      this.$refs.Conditions.refresh();
    },
    ActiveCondition() {
      this.selected[0].enable = false;
      const that = this;
      this.ActivateCondition({
        api: this.$api,
        session: this.$session,
        idCondition: this.selected[0].idCondition,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then((resp) => {
          that.busy = false;
          that.$refs.Conditions.refresh();
          that.toast(
            that.$t("views.conditions.ConditionActivated"),
            resp.data.message
          );
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
    RemoveCondition() {
      const that = this;
      this.DeleteCondition({
        api: this.$api,
        session: this.$session,
        idCondition: this.selected[0].idCondition,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then((resp) => {
          that.busy = false;
          that.$forceUpdate();
          that.$refs.Conditions.refresh();
          that.toast(
            that.$t("views.conditions.ConditionRemoved"),
            resp.data.message
          );
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
  },
  mounted() {
    this.busy = true;
    this.getConditions({
      session: this.$session,
      api: this.$api,
      enabled: true,
    })
      .then(() => {
        this.busy = false;
      })
      .catch((e) => {
        console.log(e);
        this.busy = false;
      });
  },
  computed: {
    ...mapGetters("Conditions", ["conditions"]),
    fields() {
      return [
        {
          key: "idCondition",
          sortable: true,
          label: this.$t("views.conditions.fields.Id"),
        },
        {
          key: "sumlageName",
          sortable: true,
          label: this.$t("views.conditions.fields.Name"),
        },
        {
          key: "enabled",
          sortable: false,
          label: this.$t("views.conditions.fields.Enabled"),
        },
      ];
    },
    rows() {
      return this.conditions.length;
    },
    isSelected() {
      return this.selected.length < 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
