<template>
  <b-form class="my-3" @submit="onSubmit" @reset="onReset" v-if="show">
    <b-row v-if="busy">
      <b-col class="text-center">
        <simple-loader />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col xs="12" sm="8" md="9" lg="9" xl="9">
        <label class="sr-only">{{ $t("general.name") }}:</label>
        <b-form-group
          label-for="ou-input-name"
          :invalid-feedback="$t('views.conditions.ConditionNameRequired')"
          :state="ConditionNameState"
        >
          <b-form-input
            v-model="ConditionName"
            :placeholder="$t('views.conditions.EnterConditionName')"
            id="ou-input-name"
            cols="12"
            size="sm"
            trim
            :state="ConditionNameState"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button variant="success" type="submit" block size="sm">{{
          $t("general.save")
        }}</b-button>
      </b-col>
      <b-col>
        <b-button variant="danger" type="reset" block size="sm">{{
          $t("general.cancel")
        }}</b-button>
      </b-col>
      <!-- <b-form-checkbox v-model="checked" name="check-button" switch>
        Enable
      </b-form-checkbox>-->
    </b-row>
  </b-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      ConditionName: "",
      busy: false,
      ConditionNameState: null,
    };
  },
  methods: {
    ...mapActions("Conditions", ["AddCondition"]),
    ...mapGetters("Conditions", ["conditions"]),
    onSubmit(oEvent) {
      oEvent.preventDefault();
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      const that = this;
      that.busy = true;
      that
        .AddCondition({
          api: that.$api,
          session: that.$session,
          sName: that.ConditionName,
          idOU: this.$session.get("UnitOrganitaions"),
        })
        .then((resp) => {
          that.resetForm();
          this.$bvToast.toast(resp.data.message, {
            title: this.$t("views.conditions.ConditionAdded"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch(() => {
          that.resetForm();
        });
    },
    onReset(oEvent) {
      oEvent.preventDefault();
      this.resetForm();
    },
    resetForm() {
      this.busy = false;
      // this.show = false;
      this.$emit("update:show", false);
      this.ConditionName = "";
      this.ConditionNameState = null;
    },
    checkFormValidity() {
      let check = (this.ConditionNameState = true);
      if (!this.ConditionName) this.ConditionNameState = check = false;
      return check;
    },
  },
};
</script>

<style></style>
